<template>
  <v-layout class="justify-center fill-height">
      <!-- <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[PDFExportFunctionData]"	:PassedFunction="PDFExportFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="PDFPOData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="false" :PushCompletedFile="!PDFDownloadOnly"					
        /> -->
        <v-card id="NewPOElement" max-width="1000">
        <v-card-title v-if="$route.meta && $route.meta.id === 'PurchaseRequisitionsCollection'" class="elevation-3 recordtoolbar white--text justify-center">New PO Review
        </v-card-title>
         <v-card-title v-if="$route.meta && $route.meta.id !== 'PurchaseRequisitionsCollection' && POData.Progress" class="elevation-3 recordtoolbar white--text justify-center">{{POData.Progress.Name}}
        </v-card-title>
        <v-card-text>
            
            <span v-for="(elmnt,elmntindex) in PDFExportFunctionData.Pages[0].Elements" :key="elmnt.itemObjKey" @click="ActivateActiveElement(elmnt,elmntindex)"
                >
                <span v-html="elmnt.Output">
                </span> 
                <!-- {{elmnt.Output}} -->
                </span>
          <!-- here come PDFHTMLRecordEditor component basically like pdf export builder
          this component loops per element a v-card-text, and a row. depending fullwidth or not it adds col
          that wil however not work considering everything is one element. statis positioning applies and the problem in that is, you never get a full view doc, always "fit to one page" logic... -->
        <v-card-text>
          <v-row class="justify-space-between"
            no-gutters
          >
            <v-col cols="6" style="align-self: center;"
            >
             <v-img width="80%"             
                contain
                src="@/assets/logo.png">
              </v-img>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>
                    Bill To
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
             <v-list-item dense v-html="POData.PO_Bill_To_Address_Text">
              </v-list-item>
            </v-col>
            <v-col cols="6" >
               <v-card-title class="mediumoverline"> {{POData.PO_Number}}
                  </v-card-title>
                <v-list class="background">
                 
                   <v-list-item dense v-for="field in RightHeaderFields" :key="field.itemObjKey">
                      <v-list-item-content  :style="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address' ? 'height: 350px;padding-left: 5px;' : 'height: 100%;padding-left: 5px;'">
                          {{field.DisplayName}}
                      </v-list-item-content>
                      <!-- <v-divider></v-divider> -->
                      <v-list-item-content  style="padding-left: 5px;justify-self: end;" v-if="field.CommonFieldType !== 'Physical Address' && field.CommonFieldType !== 'Postal Address'">
                       
                      <FieldViewerComponent  :field="field" :Record="POData"
                      />
                      </v-list-item-content>
                      <v-list-item-content class="background" v-if="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address'">
                         
                    <v-list class="transparent mx-4" outlined>
                        <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content class="configtext--text">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content class="datatext--text">
                                {{POData[breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                      </v-list-item-content>
                      
                  </v-list-item>
                    <v-list-item dense>
                <v-switch @change="RefreshDialog()" :disabled="CollectionorDeliveryDisabled" v-model="POData.Delivery" label="Delivery?" />
              </v-list-item>
                </v-list>
            </v-col>
          </v-row>
        
        </v-card-text>
        <v-card-text>
           <v-row v-if="POData.Supplier"
            no-gutters
          >
            <v-col>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Vendor
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{POData.Supplier.Supplier_Name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- {{SelectedSupplier.Invoice_Address_Country_Region}} -->
             <v-list-item v-if="SelectedSupplier" dense v-html="POData.Supplier_Invoice_Address_Text">
              </v-list-item>
            </v-col>
            <v-col >
            
              <v-list-item v-if="OrderforDelivery">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Ship To:
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{SelectedWarehouse.Company_Name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
                <v-list-item v-if="OrderforDelivery" dense v-html="POData.PO_Delivery_Address_Text">
              </v-list-item>
              <v-list-item v-if="!OrderforDelivery">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    For Collection at:
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{SelectedSupplier.Supplier_Name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
                <v-list-item v-if="!OrderforDelivery" dense v-html="POData.Supplier_Collection_Address_Text">
              </v-list-item>
            </v-col>
           </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <!-- hide-default-footer -->
        <!-- <v-card-text>
          <span  v-html="HeadersforTable">
          </span>
        </v-card-text> -->
        <v-card-text style="padding:30px;border: 1px solid grey;">
          <v-form ref="polineitemsform">
          <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="HeadersforTable"
      :items="LineItems"
      
     
     class="transparent"
      >
      <template v-slot:[`item.Qty`]="{ item }">
        <v-text-field style="font-size:13px;" :rules="PRQuantityRules(item)" v-model.number="item.Qty"  type="number"/>
      </template>
      <template v-slot:[`item.Price`]="{ item }">
        <v-text-field :disabled="$route.meta && $route.meta.id === 'GoodsReceiving'" style="font-size:13px;" :value="CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)" v-model.number="item.Price" :label="item.Last_Quoted_Price ? CurrencyFormatter(item.Last_Quoted_Price,$store.state.DefaultCurrency.Currency)+' (Q)' : ''"  type="number"/>
      </template>
      <template v-slot:[`item.Delivery_Date`]="{ item }">
        <span v-if="item.Delivery_Date.toDate">
        {{item.Delivery_Date.toDate().toDateString()}}
        </span>
        <span v-else>
        {{item.Delivery_Date.toDateString()}}
        </span>
         <!-- <v-menu 
            v-model="item.Delivery_DateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            >
            <template v-slot:activator="{ on }">
                <v-text-field class="smallinput"
                label="Delivery Date"
                prepend-icon="mdi-calendar-month"
                readonly  :rules="[]"
                v-on="on"
                v-model="item.Delivery_Date_Input"
                ></v-text-field>
                </template>
            <v-date-picker  v-model="item.Delivery_Date_Input"
                    @input="item.Delivery_DateMenu = false"></v-date-picker>
            </v-menu> -->
      </template>
      <template v-slot:[`item.Sub_Total`]="{ item }">
         {{CurrencyFormatter(item.Sub_Total,$store.state.DefaultCurrency.Currency)}}
      </template>
          </v-data-table>
          </v-form>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-text>
           <v-row
            no-gutters
            style="padding-top: 10px;"
          >
            <v-col cols="6">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Notes
                  </v-list-item-subtitle>
                  <span class="caption" v-html="POData.Footer_Note">
                  </span>
                </v-list-item-content>
              </v-list-item>
           
            </v-col>
            <v-col cols="6">
              <v-list-item  dense>
                <v-list-item-content>
                <v-list-item-subtitle>
                 SubTotal
                </v-list-item-subtitle>
                </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(POData.Sub_Total,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Discount ({{SelectedSupplier.Default_Discount}}%)
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(POData.Discount_Total,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="POData.Delivery">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Freight
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(POData.Freight_Total,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="SelectedSupplier.Tax_Percentage">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Tax ({{SelectedSupplier.Tax_Percentage}}%)
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(POData.Tax_Total,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="border: 2px solid black;">
                 <v-list-item-content>
                <v-list-item-title>
                  Total:
                </v-list-item-title>
                 </v-list-item-content>
                <v-list-item-content>
                  {{CurrencyFormatter(POData.Grand_Total,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
            </v-col>
           </v-row>
        </v-card-text>
        </v-card-text>
        <v-card-actions v-if="$route.meta && $route.meta.id === 'PurchaseRequisitionsCollection'">
          <v-btn @click="CancelProcessNewOrder()" dark color="warning">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
           <v-btn dark class="blue" @click="DownloadPO(true)">
            <v-icon>mdi-download</v-icon>Download
          </v-btn>
          <v-btn dark class="success" @click="ProcessPO()">
            Save
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="$route.meta && $route.meta.id !== 'PurchaseRequisitionsCollection'">
          <v-spacer>
          </v-spacer>
          <v-btn dark class="success" @click="UpdatePOProgress({ID: 1000002, Name: 'Ordered'})" v-if="POData.Progress && POData.Progress.Name === 'Draft'">
            Process</v-btn>
          <!-- <v-btn dark class="success" @click="ReceiveItems()" v-if="selectedreceivingfile && $route.meta && $route.meta.id === 'GoodsReceiving'">
            Receive</v-btn> -->
        </v-card-actions>
      </v-card>
  </v-layout>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'

export default {
    props: ['System','SystemEntities','POData','PDFExportFunctionData','RightHeaderFields','HeadersforTable','LineItems','SelectedSupplier','DocRef','LineItemsRef'],
    components: {FieldViewerComponent,PDFExporter},
    data() {
        return {
          OrderforDelivery: false,
          Bill_To_Address: {Name: 'Bill_To_Address',FieldBreakdown: []},
          Delivery_Address: {Name: 'Delivery_Address',FieldBreakdown: []},
          Invoice_Address: {Name: 'Invoice_Address',FieldBreakdown: []},
          Collection_Address: {Name: 'Collection_Address',FieldBreakdown: []},
        }
    },
    computed:{
      CollectionorDeliveryDisabled(){
        let disabled = false
        if(this.SelectedSupplier.Collection){
          disabled = true
        }
        this.Collection_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
          if(typeof this.ComputedPOObject[newname] === 'undefined'){
            disabled = true
          }
        })
        return disabled
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
    },
    created(){

    },
    methods:{
      ReceiveItems(){
        console.log('honeybadger! I create GRV',this.POData,this.LineItems,this.DocRef)
        //so how do we create GRV? I say seriously replicate the entire poelineitem object man carry on who gives a rat's ass right? 
        //sure but TRansacitonDocid need to become the newly created GRV doc. And also I must say I don't think GRV line item should contain the GRV doc...but we do need a GRV doc to receive
        //also, we do need uhm...we do need....Reference...which would be PO or Po line item. No, it will remain text. yes. a text field, still need GRV dialog actually
        //finally yeah...the "Qty" should be different. Just listen. Uhm....The Qty should be "Outstanding_Qty"...because order is "On Order"...?
      },
      UpdatePOProgress(progress){
        console.log(progress,this.LineItems,this.DocRef)
        //but first prep polineitems function to update solineitems on it with progress...I think? des that trigger solineitems to update???
        this.DocRef.update({
          Progress: progress
        })
      },
      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      PRQuantityRules(item){
        return this.$route.meta && this.$route.meta.id !== 'GoodsReceiving' ? [ 
              v => !!v || "This field is required",
              v => ( v && v >= item.Min_Qty ) || "Qty should be no less "+item.Min_Qty,
          ] : [ 
              v => !!v || "This field is required",
              v => ( v && v <= item.Outstanding_Qty ) || "Qty should be no less "+item.Outstanding_Qty,
          ]
      },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    }
}
</script>

<style>

</style>
